<template>
     <SignupConfirm/>
</template>

<script>
// @ is an alias to /src
import SignupConfirm from '@/components/login/SignupConfirm.vue'

export default 
{

  components: 
  {
    SignupConfirm
  }
}
</script>