<template>
  <div class="content">
    <main class="flex-shrink-0 form-signin">
      <div class="content p-4 box-login shadow">
        <form @submit.prevent="Login()" method="post">
          <img class="mb-0" :src="require('@/assets/img/login.png')" style="width:30%;">
          <br>
          <img class="mb-1" :src="require('@/assets/img/brand.png')" style="width:50%;">
          <h4 class="text-color-1 mb-3 fw-bold">Verify it's you</h4>
           <div class="text-start">
            <label class="text-color-1">Verification code</label>
            <input type="text" v-model='login.names' class="form-control" id="name" placeholder="Names" required="required">
            <input type="password" v-model='login.code' class="form-control" id="code" placeholder="Code" minlength="6" maxlength="6" required="required">
          </div>
          <hr>
          <button :disabled="Form.submit" class="button-1 w-100 btn btn-primary" type="submit">Confirm</button>
        </form>
      </div>
    </main>
    <p class="mt-0 text-muted">© Work Mileage 2022 </p>
  </div>
</template>

<script>
  import Api from '@/services/Api';

  export default 
  {
    data: () => (
    {
      login: 
      {
        code : "",
        names:""
      },
      response: 
      {
        message: "",
        status: "Empty"
      },
      Form: 
      {
         submit: false
      }
    }),
    methods: 
    {
      Login() 
      {
        this.Form.submit = true

        Api.post("/login/signup/confirm",this.login).then((result) => 
        {
          this.response.message =  result.data.msg
          this.response.status =  result.data.status

          if(this.response.status == "true")
          {
            this.$moshaToast( ''+this.response.message+'', { type: 'default', position:'bottom-right', transition:'zoom' })
            setTimeout( () => this.$router.push({ path: '/login'}), 5000);
          }

          if(this.response.status == "false")
          {
            this.$moshaToast( ''+this.response.message+'', { type: 'default', position:'bottom-right', transition:'zoom' })
            setTimeout( () => 
            {
              this.Form.submit = false
            }, 5000);
          }
        })
      }
    }
  }
</script>

<style scoped>
.link-color
{
  color: var(--color-three);
  text-decoration: none;
}
.content 
{
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
.box-login
{
    background-color: var(--color-two);  
    border-radius: 5px;  
}
.text-color-1
{
    color:var(--color-one);
}
.form-signin 
{
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.button-1
{
    background-color: var(--color-one);
    border:1px solid var(--color-one);
}
.button-1:hover
{
    background-color: var(--color-one);
    border:1px solid var(--color-one);
}

.form-signin .checkbox 
{
  font-weight: 400;
}

.form-signin input[type="text"] 
{
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: var(--color-five);
  border: 1px solid var(--color-five);
}

.form-signin input[type="email"] 
{
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: var(--color-five);
  border: 1px solid var(--color-five);
}

.form-signin input[type="password"] 
{
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: var(--color-five);
  border: 1px solid var(--color-five);
}
.bd-placeholder-img 
{
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  @media (min-width: 768px)
   {
    .bd-placeholder-img-lg 
    {
      font-size: 3.5rem;
    }
  }


.h4, h4 
{
    font-size: calc(1rem + .3vw);
}
</style>
